// Utilities
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

// Components
import Completed from "./Components/Completed";
import TicketForm from "./Components/TicketForm";
import NavBar from "./Components/NavBar";
import Pending from "./Components/Pending";
import { login, logout } from "./Components/utils/JWTAuth";

// Styles
import "./App.css";

const App = () => {
    const [ticketCounter, setTicketCounter] = useState(0);
    const [loginProfile, setLoginProfile] = useState("");
    const [isAuth, setIsAuth] = useState(
        JSON.parse(localStorage.getItem("iA")) || false
    );

    const [errorMessage, setErrorMessage] = useState("");
    const [causes, setCauses] = useState([]);

    const handleLogin = (data) => {
        async function getLoginResult() {
            const response = await login(data);
            handleLoginResponse(response);
        }

        getLoginResult();

        const handleLoginResponse = (response) => {
            if (response.message === "Successful login.") {
                setLoginProfile(response.username);
                setIsAuth(true);
                localStorage.setItem("iA", "true");
                localStorage.setItem("access_token", response.jwt);
            } else {
                setErrorMessage("Login failed!");
            }
        };
    };

    const handleLogout = () => {
        logout();

        setLoginProfile(null);
        setIsAuth(false);
    };

    const handleTicketCounter = (ticketCounter) => {
        setTicketCounter(ticketCounter);
    };

    useEffect(() => {
        const token = localStorage.getItem("access_token");

        if (token) {
            try {
                const decoded = jwtDecode(token);
                setLoginProfile(decoded.data.username);
                setIsAuth(true);
            } catch (error) {
                console.error("Token decoding failed:", error);
                setIsAuth(false);
                localStorage.removeItem("access_token");
            }
        }
    }, []);

    return (
        <BrowserRouter>
            <NavBar
                handleLogin={handleLogin}
                handleLogout={handleLogout}
                loginProfile={loginProfile}
                pendingsStatus={ticketCounter}
            />
            {isAuth && (
                <Routes>
                    <Route
                        exact
                        path='/'
                        replace
                        element={
                            <Pending
                                isAuth={isAuth}
                                setCauses={setCauses}
                                setTicketCounter={setTicketCounter}
                                profile={loginProfile}
                                handleTicketCounter={handleTicketCounter}
                                pendingsStatus={ticketCounter}
                            />
                        }
                    />
                    <Route
                        path='/form'
                        replace
                        element={
                            <TicketForm
                                causes={causes}
                                handleTicketCounter={handleTicketCounter}
                                pendingsStatus={ticketCounter}
                            />
                        }
                    />
                    <Route
                        path='/completed'
                        element={<Completed />}
                    />
                </Routes>
            )}
            {/**
    Authenticates the user.
     */}
            {errorMessage === "Login failed!" && (
                <>
                    <div
                        className='loginPopupContainer'
                        style={{
                            border: "4px solid #da1a35",
                            borderRadius: "1em",
                            background: "#0a0a0a",
                        }}>
                        Wrong username or password!
                    </div>
                </>
            )}
        </BrowserRouter>
    );
};

export default App;
